import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import NormalPageBlock from "../components/normal-page-block";

class WorkingGroups extends Component {
    render() {
        const workingGroups = this.props.data.allWordpressWpWorkingGroups.edges;

        return (
            <>
                { workingGroups.map(({ node }: any) => (
                    <Layout headerElement={<NormalPageBlock siteTitle="Working Groups" />}>
                        <SEO title="Working Groups" />
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div key={node.acf.title} className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="post-item border">
                                            <div className="post-item-wrap" style={{border: 0}}>
                                                <h4 className="display-4 text-center"><span>{node.title}</span></h4>
                                                <div className="post-item-description">
                                                    <p dangerouslySetInnerHTML={{__html: node.acf.content }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                ))}
            </>
        );
    }
}

export default WorkingGroups

export const pageQuery = graphql`
query WorkingGroupQuery {
  allWordpressWpWorkingGroups {
    edges {
      node {
        title
        acf {
          content
        }
      }
    }
  }
}
`